@keyframes jump {
  0% {
    transform: scale(100%);
  }
  50% {
    transform: scale(120%);
  }
  100% {
    transform: scale(100%);
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  50% {
    transform: scale(1.5);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}
