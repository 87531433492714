img {
  max-width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@page {
  width: 100vw;
  margin: 0;
  font-size: 1.3em;
}
